import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const ButtonStyled = styled(MuiButton, {
  shouldForwardProp: prop =>
    !['rounded', 'disableElevation', 'uppercase'].includes(prop),
})(({ rounded, disableElevation, uppercase, color, theme }) => ({
  ...(rounded && { borderRadius: 24 }),
  ...(disableElevation && {
    boxShadow: 'none',
    '&:hover': { boxShadow: 'none' },
  }),
  ...(uppercase && {
    textTransform: 'uppercase',
  }),
  ...(color === 'primaryLight' &&
    disableElevation && {
      backgroundColor: theme.palette.grayscale[200],
    }),
}));
