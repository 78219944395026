import { Box, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import Paper from '@/components/ui/Paper/Paper';

export const MainWrapper = styled(Box)(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(variant === 'large' && {
    [`${Wrapper}`]: {
      width: '100%',
      height: 56,
      backgroundColor: theme.palette.grayscale[100],
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  }),
}));

export const Wrapper = styled(Paper)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: theme.spacing(0.5, 0),
}));

export const Input = styled(InputBase)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  minWidth: 24,
  height: 20,
  backgroundColor: theme.palette.grayscale[100],
  borderRadius: theme.shape.borderRadius,
  fontSize: 14,
  fontWeight: theme.typography.fontWeightMedium,

  input: {
    padding: 0,
    textAlign: 'center',
  },
  // Hide native number input arrows
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const Button = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  padding: theme.spacing(0, 0.5),
}));
